// config.js

const bizIcon = "https://mybizsolutions.us/static/media/icon-app-logo.83ff8bf39a11df9fb7ac.jpg";
const imageOne = "https://media.istockphoto.com/id/160113691/photo/old-fashioned-apartment-building.jpg?s=612x612&w=0&k=20&c=iPxlbrvciFSLEwocrn25DOzcUdyDf819he9WnqQ4BTY=";
const imageTwo = "https://media.istockphoto.com/id/1386954602/photo/travel-destination-vintage-building-originally-built-in-1800s-in-portsmouth-nh-new-england.jpg?s=612x612&w=0&k=20&c=0Oety0A8qW2x4O7Ub9uBMD9ynshcqLIa-4irT2hFG_Q=";
const imageThree = "https://media.istockphoto.com/id/1280935242/photo/street-in-college-hill-historic-district.jpg?s=612x612&w=0&k=20&c=OihhtGwhNV4GGlTOC1pJwwyKYNBtvX_oOPZyHuwqWKE=";
const imageFour = "https://media.istockphoto.com/id/2165566927/photo/the-old-american-architecture-in-field-forest-at-cuyahoga-valley-national-park-in-cleveland-a.jpg?s=612x612&w=0&k=20&c=b3fjTCiYI3FOCmLmTgwi-D7aqFinIbgmkBRnUxEI_zw=";
const imageFive = "https://media.istockphoto.com/id/1088471506/photo/nova-scotia.jpg?s=612x612&w=0&k=20&c=Nal45MDMrVRU6jZTAieZl4Swn4hTZs1Rkn5cNse_Vr8=";

export const siteConfig = {
  title: "Gennie Smith | Coldwell Banker Realty",
  metaDescription: "Gennie Smith | Coldwell Banker Realty",
  theme: {
    primaryColor: "blue-500",
    secondaryColor: "gray-900",
    accentColor: "text-blue-500"
  },
  logo: {
    src: bizIcon,
    alt: "Gennie Smith | Coldwell Banker Realty",
  },
  navigation: [
    { name: "Services", sectionId: "services" },
    { name: "About", sectionId: "about" },
    { name: "Location", sectionId: "location" },
  ],
  businessDetails: {
    name: "Gennie Smith | Coldwell Banker Realty",
    headline: "Your Real Estate Matchmaker in West Virginia",
    address: "700 West Virginia Street, Charleston, WV 25302",
    description: `
      Gennie Smith, a dedicated partner with Coldwell Banker Realty, is your trusted advisor for buying, selling, and investing in West Virginia real estate. With unparalleled knowledge of the local market and a commitment to client satisfaction, Gennie provides a personalized and seamless experience to help you achieve your real estate goals. From first-time homebuyers to seasoned investors, Gennie is here to guide you every step of the way.`,
  },
  images: {
    business: {
      src: imageOne,
      alt: "Your Trusted Real Estate Expert in West Virginia",
      width: 2432,
      height: 1442
    }
  },
  features: [
    {
      description: 
        "Buying or selling a home is one of the biggest decisions you'll make, and Gennie Smith is here to make the process smooth and stress-free with thoughtful guidance every step of the way.",
    },
    {
      description: 
        "With a deep understanding of West Virginia's neighborhoods and local market trends, Gennie provides expert advice to help you find the perfect home or maximize the sale of your property.",
    },
    {
      description: 
        "Dedicated to exceeding expectations, Gennie ensures you feel supported, informed, and confident throughout your real estate journey, making every transaction seamless and rewarding.",
    },
  ],  
  services: {
    sectionTitle: "Communities Served",
    description: 
    "Gennie Smith | Coldwell Banker Realty is proud to serve the vibrant communities of West Virginia, providing personalized real estate services tailored to each client’s needs. Whether you’re a first-time buyer, upgrading to your dream home, or investing in property, Gennie’s local expertise and dedication ensure you find the perfect fit for your lifestyle and goals.",
    callouts: [
      {
        name: 'Single Family',
        description: "Explore the charming neighborhoods of Charleston, WV, with homes that offer spacious layouts, excellent schools, and a family-friendly atmosphere.",
        imageSrc: imageTwo,
        imageAlt: 'Beautiful single-family home in Charleston, WV',
      },
      {
        name: 'Condo/Townhouse/Co-Op',
        description: "Discover modern and convenient living options in vibrant communities, perfect for professionals and those seeking low-maintenance lifestyles.",
        imageSrc: imageThree,
        imageAlt: 'Modern townhouse community in West Virginia',
      },
      {
        name: 'Lot/Land/Acreage',
        description: "Looking to build your dream home? Gennie Smith can help you find the perfect lot or acreage with breathtaking West Virginia views.",
        imageSrc: imageFour,
        imageAlt: 'Scenic plot of land in West Virginia ready for development',
      },
    ],
  },
  about: {
    sectionTitle: "About Gennie Smith | Coldwell Banker Realty",
    description: "Gennie Smith is a dedicated real estate expert serving the West Virginia market. With extensive local knowledge and a commitment to personalized service, Gennie ensures every client’s buying, selling, or investing experience is seamless and rewarding. Backed by the trusted Coldwell Banker Realty brand, Gennie strives to exceed expectations by delivering tailored solutions and exceptional results for every real estate journey.",
    image: {
      src: imageFive,
      alt: "Gennie Smith, trusted West Virginia real estate expert at Coldwell Banker Realty",
      width: 1200,
      height: 800
    }
  },
  location: {
    sectionTitle: "Our Location",
    destinationAddress: "700 Virginia Street East, Charleston, WV 25301",
    googleMapsEmbedUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3165.948430067216!2d-81.6324235!3d38.3498165!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x884c8392b0e5b0f3%3A0xabcdef123456789!2s700%20Virginia%20Street%20East%2C%20Charleston%2C%20WV%2025301!5e0!3m2!1sen!2sus!4v1710407450828!5m2!1sen!2sus",
    subCategories: [
      { 
        name: 'Apple Maps', 
        url: 'https://maps.apple.com/?address=700%20Virginia%20Street%20East,%20Charleston,%20WV%2025301&ll=38.3498,-81.6324&q=700%20Virginia%20Street%20East',
      },
      { 
        name: 'Bing Maps', 
        url: 'https://www.bing.com/maps?q=700+Virginia+Street+East,+Charleston,+WV+25301&FORM=HDRSC6',
      },
      { 
        name: 'Google Earth', 
        url: 'https://earth.google.com/web/search/700+Virginia+Street+East,+Charleston,+WV+25301/@38.3498,-81.6324,15a,814.57589625d,35y,0h,0t,0r',
      },
      { 
        name: 'Google Maps', 
        url: 'https://www.google.com/maps/place/700+Virginia+Street+East,+Charleston,+WV+25301/@38.3498,-81.6324,15z',
      }
    ]
  },  
  contact: {
    sectionTitle: "Ready to Find Your Dream Home?",
    description: "Contact Gennie Smith | Coldwell Banker Realty to discuss your real estate goals or schedule a consultation. Whether you’re buying, selling, or investing in the Charleston, WV area, Gennie is here to provide expert guidance and personalized support every step of the way.",
    socialLinks: {
      facebook: "https://www.genniesoldit.com/",     
      instagram: "https://www.genniesoldit.com/",     
      twitter: "https://www.genniesoldit.com/",     
      linkedin: "https://www.genniesoldit.com/",     
      youtube: "https://www.genniesoldit.com/",     
    },
    contactNumbers: [
      "Mobile: 414-563-7888",
    ],
    logo: bizIcon,
    emailRecipient: "gennie.smith@cbrealty.com"
  },
  footer: {
    bizSolutionsLogo: "https://mybizsolutions.us/static/media/icon-app-logo.83ff8bf39a11df9fb7ac.jpg"
  }
};
